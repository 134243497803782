/* You can add global styles to this file, and also import other style files */

:root {
  --main: #81d1d0;
  --secondary: #fe9d97;
  --header-text: #333333;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.doctor-profile nb-user .user-container .info-container .user-name {
  font-size: 1.3rem !important;
}

.doctor-profile .ah-wrapper {
  min-width: 720px !important;
}

.doctor-profile nb-select .select-button {
  min-width: 100px !important;
}

.patient-profile .ah-wrapper {
  min-width: 720px !important;
}

.patient-profile .transactions-wrapper {
  min-width: 700px !important;
}

.patient-profile .topup-history-wrapper {
  min-width: 550px !important;
}

nb-tabset .tab.active .tab-link::before {
  background-color: var(--main) !important;
  color: var(--main) !important;
}

nb-tabset .tab.active .tab-link {
  color: var(--main) !important;
}

nb-tabset .tab .tab-link:hover {
  color: var(--main) !important;
}

nb-tabset .tab .tab-link:hover::before {
  background-color: var(--main) !important;
}

.header-container nb-icon,
.header-container nb-user .user-name,
.header-container nb-user .initials {
  color: white !important;
}

.tox-tinymce {
  border-radius: 0.25em !important;
  border-color: #e4e9f2 !important;
}

.tox-toolbar {
  background-color: #f7f9fc !important;
  border-color: #e4e9f2 !important;
}

.tox-edit-area__iframe,
.tox-menubar,
.tox-toolbar__primary {
  background-color: #f7f9fc !important;
  border-color: #e4e9f2 !important;
}

.tox-toolbar__group {
  border-color: #e4e9f2 !important;
}

.tox-toolbar__overflow {
  background-color: #f7f9fc !important;
  border-color: #e4e9f2 !important;
}

.banner-tabset ul.tabset {
  display: flex !important;
  justify-content: center !important;
}

.banner-actions nb-icon {
  color: white !important;
}

.page-item.active .page-link {
  background-color: var(--main);
  border-color: var(--main);
}

#listBtn {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#gridBtn {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

button {
  line-height: 1.25rem !important;
}

ngb-pagination ul li {
  font-size: 12px !important;
}

/* list */
.list-header {
  background-color: var(--main);
  color: var(--header-text) !important;
  font-weight: 700 !important;
  height: auto;
  border-radius: 0.25em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 37px !important;
}

.nb-theme-default nb-card-header {
  color: var(--header-text) !important;
  font-weight: 700 !important;
}

.head-cell {
  color: var(--header-text) !important;
  font-weight: 700 !important;
}

.nb-theme-default nb-list-item {
  font-weight: 500 !important;
}

.nb-theme-default .nb-tree-grid-row {
  font-weight: 500 !important;
}

#nb-list-id {
  background: white;
}

#nb-list-id nb-actions {
  color: var(--main);
  margin: 0.2em !important;
}

#nb-list-id nb-action {
  font-size: 16px;
}

ngb-pagination {
  margin-top: 10px;
}

.nb-theme-default [nbButton].appearance-filled.size-small {
  padding: 0.5rem 0.875rem;
}

.nb-theme-default [nbButton].appearance-filled.status-success {
  background-color: var(--main) !important;
  border-color: var(--main) !important;
  color: white !important;
}

.nb-theme-default [nbButton].appearance-filled.status-action {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: white !important;
}

.nb-theme-default [nbButton].appearance-filled.status-action:disabled {
  background-color: #f8d8d7 !important;
  border-color: #f8d8d7 !important;
  color: whitesmoke !important;
}

.nb-theme-default [nbButton].appearance-outline.status-success {
  background-color: rgba(0, 214, 143, 0.08);
  border-color: var(--main) !important;
  color: var(--main) !important;
}

.nb-theme-default nb-toggle.status-success .toggle.checked {
  background-color: var(--main) !important;
  border-color: var(--main) !important;
}

.nb-theme-default nb-toggle.status-success .toggle {
  background-color: rgba(0, 214, 143, 0.08);
  border-color: var(--main) !important;
}

.nb-theme-default nb-toggle.status-success .toggle-switcher nb-icon {
  color: var(--main) !important;
}

.nb-theme-default nb-card.status-success nb-card-header {
  background-color: var(--main) !important;
  border-bottom-color: var(--main) !important;
}

.orange {
  color: orange !important;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.brown {
  color: brown !important;
}

.action-icon-size {
  font-size: 20px;
}

small {
  color: grey;
}

.require-span {
  font-style: italic;
  color: red;
}

.my-full-width {
  width: 100% !important;
}

.card-title {
  font-size: 16px;
}

.icon-gray {
  color: #8f9bb3;
}

.custom-icon-size {
  font-size: 18px;
}

.action-icon-size {
  font-size: 20px;
}

.icon-yellow {
  color: gold;
}

.icon-danger {
  color: red;
}

.icon-green {
  color: var(--main) !important;
}

h6 {
  color: lightgray;
}

.data-wrapper {
  position: relative;
}

.data-wrapper:hover .actions-wrapper {
  opacity: 1;
}

.actions-wrapper {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
}

.card-div-section {
  text-align: center;
}

.border-bottom {
  border-bottom: 1px dashed lightgrey;
}

.margin-top {
  margin-top: 11px;
}

.nb-user-margin-bottom {
  margin-bottom: 5px;
}

.div-section {
  margin-bottom: 15px !important;
}

.yt-content-container {
  padding: 2.25rem 2.25rem 0.75rem;
}

.no-data-div {
  padding: 25px;
  text-align: -webkit-center;
  text-align: center;
  color: lightgrey;
  font-size: 27px;
  font-weight: 600;
}

.list-item-yt {
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
}

.page-item.active .page-link {
  z-index: 0 !important;
}

.sub-header-title {
  font-size: 17px !important;
  font-weight: 600 !important;
}

nb-list-item div,
nb-card-header div {
  padding: 0 5px !important;
  word-break: break-word;
}

.p-l {
  padding-left: 0 !important;
}

.p-r {
  padding-right: 0 !important;
}

nb-dialog-container {
  overflow: auto !important;
}

.nb-theme-default nb-sidebar.expanded {
  width: 18rem;
}

.nb-theme-default nb-sidebar .main-container {
  width: inherit;
}

.count {
  padding: 2px 6px;
  background: #edf1f7;
  color: #81d1d0;
  border-radius: 5px;
}

.nb-theme-default nb-user .user-title {
  color: white;
  font-size: larger !important;
  font-weight: 700 !important;
}

*:not(i):not(.fa) {
  font-family: "Montserrat", sans-serif !important;
}
